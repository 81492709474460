import React from 'react';
import Footer from '../components/footer';
import Wrapper from '../components/Wrapper';
import Seo from '../shared/seo';
import Acnavbar from '../components/Acnavbar';
import bugsworkfavicon from '../assets/images/favicon.png';
import Hero from '../components/Hero';
import Pricing from '../components/Pricing';
import AskedQuestions from '../components/AskedQuestions';
import Integration from '../components/Integration';

const PricingPage = () => {
  return (
    <Wrapper>
      <Seo
        title="bugs.work"
        description="Move fast, stay aligned, and build better - together"
      />

      <Acnavbar
        home={true}
        pathname={'pricing'}
        logo={bugsworkfavicon}
        logoLogic={false}
      />
      <Hero
        isScrollAnimationPresent={false}
        headerText="Your Plan, Your choice"
        subHeading="bugs.work is the top rated visual feedback, bug tracking for web applications."
      />
      <Pricing />
      <div className="integration-bg text-white">
        <Integration background="dark" />
      </div>
      <AskedQuestions />
      <Footer pathname={'pricing'} />
    </Wrapper>
  );
};

export default PricingPage;
