import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import '../assets/scss/_common.scss';
import '../assets/scss/_variables.scss';
import Button from 'react-bootstrap/Button';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import arrow from '../assets/images/right-arrow-white.svg';

export default function Pricing() {
  const [selectedPlan, setSelectedPlan] = useState('year');
  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(
    'INR'
  );
  const [pricingData, setPricingData] = useState([]);
  const [pricingDataLoading, setPricingDataLoading] = useState<boolean>(false);
  const [filteredPricingData, setFilteredPricingData] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isPad, setIsPad] = useState(false);
  const desiredOrder = ['Free', 'Pro', 'Startup', 'Business', 'Enterprise'];

  const handleCurrencyChange = async (newCurrency: any) => {
    setSelectedCurrency(newCurrency);
    try {
      // Example: Fetch pricing data from an API with the updated currency
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/billing/plans?currency_code=${newCurrency}`
      );

      const data = await response.json();
      if (Array.isArray(data.data)) {
        const monthlyUSAData = data.data.filter(
          (plan: any) => plan.period_unit === selectedPlan
        );
        monthlyUSAData.sort((a: any, b: any) => {
          return desiredOrder.indexOf(a.title) - desiredOrder.indexOf(b.title);
        });
        setFilteredPricingData(monthlyUSAData);
        setPricingData(data.data);
      } else {
        console.error('Invalid data format received from the API');
      }
    } catch (error) {
      console.error('Error fetching pricing data', error);
    }
  };

  const fetchCurrencyCode = async (): Promise<'INR' | 'USD'> => {
    try {
      const ipResponse = await fetch(`https://api.ipify.org/`);
      const clientIp: string = await ipResponse.text();

      const IPSTACK_URL = await fetch(
        `${process.env.GATSBY_API_URL}/location?clientIp=${clientIp}`
      );
      const location = await IPSTACK_URL.json();

      const countryCode = location.data.countryCode;
      const currencyCode = countryCode === 'IN' ? 'INR' : 'USD';

      return currencyCode;
    } catch (_) {
      return 'USD';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setPricingDataLoading(true);
      try {
        const currencyCode = await fetchCurrencyCode();
        const response = await fetch(
          `${process.env.GATSBY_API_URL}/billing/plans?currency_code=${currencyCode}`
        );

        const data = await response.json();
        if (Array.isArray(data.data)) {
          const monthlyData = data.data.filter(
            (plan: any) => plan.period_unit === 'year'
          );
          monthlyData.sort((a: any, b: any) => {
            return (
              desiredOrder.indexOf(a.title) - desiredOrder.indexOf(b.title)
            );
          });

          setFilteredPricingData(monthlyData);
          setPricingData(data.data);
          setPricingDataLoading(false);
        } else {
          console.error('Invalid data format received from the API');
        }
      } catch (error) {
        console.error('Error fetching pricing data', error);
      }
    };

    fetchData();

    // Add an event listener to check for mobile view on component mount
    const checkIfMobileView = () => {
      setIsMobileView(window.innerWidth <= 768); // Adjust the screen width breakpoint as needed
    };

    checkIfMobileView();

    window.addEventListener('resize', checkIfMobileView);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('resize', checkIfMobileView);
    };
  }, []);

  const handlePlanChange = (newPlan: string) => {
    // Filter the pricing data based on the selected plan and period
    const filteredData = pricingData.filter(
      (plan: any) => plan.period_unit.toLowerCase() === newPlan.toLowerCase()
    );
    filteredData.sort((a: any, b: any) => {
      return desiredOrder.indexOf(a.title) - desiredOrder.indexOf(b.title);
    });
    setFilteredPricingData(filteredData);
    setSelectedPlan(newPlan);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsPad(window.innerWidth >= 1170 && window.innerWidth <= 1230);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div id="pricing" className="container my-4 my-lg-5">
      <div className="text-center mb-md-5 mb-0 ">
        <h1
          className={`base-color ${isMobileView ? 'fnt-14 fw-600' : 'bugs-heading fw-600'}`}
        >
          Tailored pricing plans designed for you
        </h1>
      </div>
      <div>
        {isMobileView ? (
          <div className="plan-frequency">
            <div
              className={`${
                isMobileView ? 'toggle-switch my-3 ms-3' : 'toggle-switch mb-5'
              }`}
            >
              <div
                className={`${
                  isMobileView
                    ? 'header-toggle-btn p-1 border'
                    : 'header-toggle-btn'
                }`}
                style={{ fontSize: isMobileView ? 'xx-small' : '' }}
              >
                <button
                  className={selectedPlan === 'month' ? 'active m-0 ' : ''}
                  onClick={() => handlePlanChange('month')}
                >
                  <span className="fw-600 fnt-12 toggle-btm-sm px-1">
                    Monthly
                  </span>
                  <br />
                  &nbsp;
                </button>
                <button
                  className={`p-1 px-2 ${selectedPlan === 'year' ? 'active m-0 ' : ''}`}
                  onClick={() => handlePlanChange('year')}
                >
                  <small className="fw-600 fnt-12 px-1">Yearly</small>
                  <br />
                  <small className="fw-800 fnt-10">Save up to 30%</small>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="plan-frequency">
            <div
              className={`toggle-switch ${isMobileView ? ' mb-5' : ' mb-1'}`}
              id="toggle-switch-desktop"
            >
              <div
                className={`${
                  isMobileView ? 'header-toggle-btn' : 'header-toggle-btn'
                }`}
                style={{ fontSize: isMobileView ? 'xx-small' : '' }}
              >
                <button
                  className={selectedPlan === 'month' ? 'active m-0 m-1' : ''}
                  onClick={() => handlePlanChange('month')}
                >
                  <span className="fw-700 toggle-btm font-14"> Monthly</span>
                  <br />
                  &nbsp;
                </button>
                <button
                  className={
                    selectedPlan === 'year'
                      ? 'active m-1 border-none bg-selected p-2 px-2 lh-1'
                      : ''
                  }
                  onClick={() => handlePlanChange('year')}
                >
                  <span className="p-0 fw-700 font-14 lh-1">Yearly</span>
                  <br />
                  <small className="fw-600 font-12 lh-1">Upto 30% off</small>
                </button>
              </div>
            </div>
          </div>
        )}

        <div id="pricing" className="container my-md-5 my-0">
          {pricingDataLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div className="row">
              {filteredPricingData.map((planType, index) => {
                return (
                  <div key={index} className="col-12 col-md-6 col-lg-4 mt-4">
                    <Card
                      className={`p-4 text-left price-card-height base-color'}`}
                    >
                      <Card.Body className="p-0">
                        <div className="d-flex">
                          <Card.Title
                            className={`${isMobileView ? 'fnt-14 fw-700' : isPad ? '' : 'fs-6 fw-700'}`}
                          >
                            {planType.title}
                          </Card.Title>
                          <div>
                            {planType.mostPopular && (
                              <div
                                className={`${'most-popular ms-2 fw-400   mt-2 badge  margin-top20 bg-pill top-button text-white'}`}
                              >
                                Most Popular
                              </div>
                            )}
                          </div>
                        </div>

                        <Card.Text>
                          <p className="fs-md-4 fs-6 fw-700 lh-1">
                            {planType.currency_code === 'USD'
                              ? planType.period_unit === 'year'
                                ? `$${(planType.price / 100 / 12).toFixed(2)}`
                                : `$${(planType.price / 100).toFixed(2)}`
                              : planType.period_unit === 'year'
                                ? `₹ ${(planType.price / 100 / 12).toFixed(2)}`
                                : `₹ ${(planType.price / 100).toFixed(2)}`}
                            <span className={` lh-1  font-14 fw-700`}>
                              / month&nbsp;
                              <span>
                                {planType.period_unit === 'year' && (
                                  <span className={`lh-1 fw-500  font-14`}>
                                    (billed yearly)
                                  </span>
                                )}
                              </span>
                            </span>
                          </p>

                          <ul className="mt-3 custom-list mx-3">
                            {planType.features.map((feature, featureIndex) => (
                              <li
                                key={`feature-${featureIndex}`}
                                className={`lh-lg font-14  fw-400`}
                              >
                                {feature.title}
                              </li>
                            ))}
                          </ul>
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer
                        className={`p-0 border-top-0 bg-transparent`}
                      >
                        <Link
                          to={`${process.env.GATSBY_APP_URL}/registration/?plan=${planType.title}`}
                          target="_blank"
                        >
                          <button
                            type="button"
                            className={`green-color  w-100 fw-600 font-14 btn btn-outline-dark '}`}
                          >
                            Select Plan
                          </button>
                        </Link>
                      </Card.Footer>
                    </Card>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
